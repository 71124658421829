// ------- animation js -------------
// new WOW().init();

// ---------- header js  ------------
$(document).ready(function() {
    var header = $("#myHeader"); 

    $(window).scroll(function() {
        if ($(window).scrollTop() > 50) {
            header.addClass("sticky");
        } else {
            header.removeClass("sticky");
        }
    });
});

// -------  menu js -------------
$(document).ready(function(){
    $(".menu-icon").click(function() {
        $('body').toggleClass("menu-open");
        $('.menu-overlay').toggleClass("is-active");
    });
    $(".menu-overlay").click(function() {
        $('body').removeClass("menu-open");
        $('.menu-overlay').removeClass("is-active");
    });
});